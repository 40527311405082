import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
// USE THE IMPORT BELOW INSTEAD IF YOU ARE USING THE PRO VERSION
// import SimpleReactLightbox from 'simple-react-lightbox-pro'

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LdAWeMaAAAAACK_yWYAPEiPUPKuxk2d2nlSDE5d">
    <SimpleReactLightbox>
    {element}</SimpleReactLightbox>
  </GoogleReCaptchaProvider>
)

export function onInitialClientRender () {
  window.scrollTo(0, 0);
};