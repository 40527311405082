exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-flight-and-hotels-booking-js": () => import("./../../../src/pages/flight-and-hotels-booking.js" /* webpackChunkName: "component---src-pages-flight-and-hotels-booking-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-statement-js": () => import("./../../../src/pages/privacy-statement.js" /* webpackChunkName: "component---src-pages-privacy-statement-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-success-page-js": () => import("./../../../src/pages/success-page.js" /* webpackChunkName: "component---src-pages-success-page-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-support-us-js": () => import("./../../../src/pages/support-us.js" /* webpackChunkName: "component---src-pages-support-us-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-city-page-js": () => import("./../../../src/templates/city-page.js" /* webpackChunkName: "component---src-templates-city-page-js" */),
  "component---src-templates-collections-js": () => import("./../../../src/templates/collections.js" /* webpackChunkName: "component---src-templates-collections-js" */),
  "component---src-templates-itinerary-view-js": () => import("./../../../src/templates/itinerary-view.js" /* webpackChunkName: "component---src-templates-itinerary-view-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-state-page-js": () => import("./../../../src/templates/state-page.js" /* webpackChunkName: "component---src-templates-state-page-js" */)
}

